<template>
  <div>
    <div class="card mb-2">
      <div class="card-body">
        <h3 class="cart-title">Subscription</h3>
        <ul class="list-group list-group-flush" v-if="hasSubscriptions()">
          <li class="list-group-item font-weight-bold">
            <div class="row">
              <div class="col">Membership</div>
              <div class="col">Valid until</div>
              <div class="col">Renew</div>
              <div class="col"></div>
            </div>
          </li>
          <li class="list-group-item" v-for="subscription in overview.subscriptions" :key="subscription.id">
            <div class="row">
              <div class="col">{{ subscription.membership.name }}</div>
              <div class="col">{{ subscription.next_invoice_date | formatDate }}</div>
              <div class="col">
                <span class="text-success" v-if="subscription.renew">Yes</span>
                <span class="text-danger" v-else>No</span>
              </div>
              <div class="col text-right">
                <!-- <button class="btn btn-sm btn-secondary mr-2">Change</button> -->
                <button class="btn btn-sm btn-danger" v-if="subscription.renew" @click="cancelSubscription(subscription.id)">Cancel</button>
                <button class="btn btn-sm btn-success" v-else @click="reactivateSubscription(subscription.id)">Reactivate</button>
              </div>
            </div>
          </li>
        </ul>
        <template v-else>
          <div class="alert alert-info">No active subscriptions</div>
          <router-link to="/subscription/" class="mr-3"><button class="btn btn-lg d-block w-100 btn-primary">New subscription</button></router-link>
        </template>
      </div>
    </div>
    <div class="card mb-2">
      <div class="card-body">
        <h3 class="cart-title">Unpaid invoices</h3>
        <ul class="list-group list-group-flush mb-2" v-if="hasInvoices()">
          <li class="list-group-item font-weight-bold">
            <div class="row">
              <div class="col">Invoice</div>
              <div class="col">Created date</div>
              <div class="col">Total</div>
              <div class="col"></div>
            </div>
          </li>
          <li class="list-group-item" v-for="invoice in overview.invoices" v-bind:key="invoice.id">
            <div class="row">
              <div class="col">#{{ invoice.id }}</div>
              <div class="col">{{ invoice.created_at | formatDate }}</div>
              <div class="col">
                <span v-if="invoice.currency.prefix">{{ invoice.currency.prefix }} </span>{{ invoice.total }}<span v-if="invoice.currency.decimal">.00</span><span v-if="invoice.currency.suffix">{{ invoice.currency.suffix }}</span>
              </div>
              <div class="col text-right">
                <!-- <button class="btn btn-sm btn-success mr-2">Download</button> -->
                <router-link :to="'/invoices/' + invoice.id" class="mr-3"><button class="btn btn-sm btn-primary">Show more</button></router-link>
              </div>
            </div>
          </li>
        </ul>
        <div class="alert alert-info" v-else>No unpaid invoices</div>
        <router-link to="/invoices" class="btn btn-secondary d-block">All invoices</router-link>
      </div>
    </div>
    <div class="card mb-2">
      <div class="card-body">
        <h3 class="cart-title">Personalia</h3>
        <router-link to="/profile" class="btn btn-secondary d-block">Profile</router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      overview: {},
    };
  },
  methods: {
    hasSubscriptions() {
      return this.overview.subscriptions && this.overview.subscriptions.length > 0;
    },
    hasInvoices() {
      return this.overview.invoices && this.overview.invoices.length > 0;
    },
    async cancelSubscription(subscription_id) {
      await this.$http.post(`/subscription/cancel/${subscription_id}`);
      const { data } = await this.$http.get("/overview");
      this.overview = data;
    },
    async reactivateSubscription(subscription_id) {
      await this.$http.post(`/subscription/reactivate/${subscription_id}`);
      const { data } = await this.$http.get("/overview");
      this.overview = data;
    },
  },
  async mounted() {
    const { data } = await this.$http.get("/overview");
    this.overview = data;
  },
};
</script>